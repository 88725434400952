<!-- citationPushConfig -->
<template>
  <div class="w center_all citationPushConfig">
    <div class="center_2">
      <div class="header">
        <ul class="crumbs clearfix">
          <li>邮件推送</li>
          <li>-></li>
          <li>引证推送</li>
        </ul>
      </div>
      <div class="content-box">
        <div>发送学者：本刊近2年发文作者</div>
        <div style="margin:20px 0">
          统计起止时间：
          <el-date-picker
            :disabled="disabled"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
            v-model="pickerTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <h3>邮件主题</h3>
        <div>
          主题1（推送标题）
          <el-input :disabled="disabled" v-model="topic" style="width:340px;margin:20px 0"></el-input>
        </div>
        <h3 style="margin-bottom:20px">邮件样式</h3>
        <div>
          <div>
            <span style="color:red">*</span>
            邮件正文
          </div>
          <el-tabs type="border-card" v-model="activeName" style="width:80%">
            <el-tab-pane label="文本框" name="one">
              <el-input :disabled="disabled" autosize v-if="activeName === 'one'" v-model="content" type="textarea" />
            </el-tab-pane>
            <el-tab-pane label="富文本" name="two">
              <editor
                :disabled="disabled"
                v-if="activeName === 'two'"
                tinymce-script-src="../../../../../js/tinymce/tinymce.min.js"
                :init="init"
                model-events="change keydown blur focus paste"
                v-model="content"
              />
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="btns" style="text-align:center" v-if="!disabled">
          <el-button @click="saveTask">保存</el-button>
          <el-button @click="pushTask">邮件推送</el-button>
        </div>
      </div>
    </div>
    <citationPushDialog ref="citationPushDialog" @pushSuccess="handlePushSuccess"></citationPushDialog>
  </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import { NewEditCitation, GetCitationList } from '@/api/citationPush'
import { mapGetters } from 'vuex'
import { TimestampToTime } from '@/assets/js/util'
import citationPushDialog from './components/citationPushDialog'
import {uploadEditorImg,getPictureStreamPath} from "@/api/configManage"
export default {
  name: 'citationPushConfig',
  data() {
    return {
      uploadEditorImg,
      pickerTime: [TimestampToTime(new Date().getTime() - 3600 * 1000 * 24 * 30, true), TimestampToTime(new Date().getTime(), true)],
      activeName: 'two',
      content: '',
      init: {
        auto_focus: true,
        min_height: 400,
        menubar: false,
        language: 'zh_CN',
        skin: 'CUSTOM',
        content_css: 'CUSTOM',
        convert_urls:false,
        plugins:
          'quickbars print preview searchreplace autolink directionality visualblocks visualchars fullscreen template code codesample charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern help link emoticons autosave indent2em lineheight formatpainter image',
        toolbar:
          'fontselect fontsizeselect bullist numlist outdent indent indent2em formatselect  formatpainter bold italic underline strikethrough superscript subscript removeformat forecolor backcolor alignleft aligncenter alignright alignjustify lineheight link undo redo image',
        toolbar_mode: 'sliding',
        quickbars_selection_toolbar: 'bold italic underline strikethrough subscript superscript removeformat',
        quickbars_insert_toolbar: false,
        fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
        font_formats:
          '微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;宋体=simsun,serif,sans-serif;仿宋体=FangSong,serif,sans-serif;楷体=KaiTi,serif,sans-serif;黑体=SimHei,sans-serif;隶书=LiSu,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;Times New Roman=Times New Roman,helvetica,sans-serif;',
        images_upload_handler: (blobInfo, success) => {
          this.upload(blobInfo,(e)=>{
            success(e)
          })
        }
      },
      topic: '文章传播表现，欢迎name老师查阅！ ',
      currentItem: {}
    }
  },
  props: {},
  components: { Editor, citationPushDialog },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    type() {
      return this.$route.query.type
    },
    id() {
      return this.$route.query.id
    },
    disabled() {
      return this.$route.query.type === 'info'
    }
  },
  mounted() {
    if (this.type === 'edit' || this.type === 'info') {
      this.getTableList()
    }
  },
  methods: {
    saveTask() {
      if (!this.content) {
        this.$message.error('请填写邮件正文')
        return
      }
      let params = {
        magId: this.magId,
        topic: this.topic,
        startTime: this.pickerTime && this.pickerTime[0],
        endTime: this.pickerTime && this.pickerTime[1],
        content: this.content
      }
      if (this.type == 'edit') {
        params.id = this.currentItem.id
      }
      console.log(params, 'params')
      NewEditCitation(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('保存成功')
          this.$router.push({
            name: 'citationPush'
          })
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    getTableList() {
      let params = {
        page: 0,
        size: 999,
        magId: this.magId
      }
      GetCitationList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          let tableData = data.content
          if (tableData.length > 0) {
            tableData.map((item) => {
              if (item.id == this.id) {
                this.currentItem = item
                this.pickerTime = [item.startTime, item.endTime]
                this.topic = item.topic
                this.content = item.content
              }
            })
            if (JSON.stringify(this.currentItem) === '{}') {
              this.$message.error('该条任务已经删除')
              setTimeout(() => {
                this.$router.push({
                  name: 'citationPush'
                })
              }, 1000)
            }
          }
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    pushTask() {
      this.$refs.citationPushDialog.dialogPushVisible = true
      this.$refs.citationPushDialog.currentTask = this.currentItem
    },
    handlePushSuccess() {
      this.$router.push({
        name: 'citationPush'
      })
    },
    upload(blobInfo,fn) {
      console.log(blobInfo,fn);
      let formData = new FormData()
      formData.append('file', blobInfo.blob())
      uploadEditorImg(formData).then(res => {
        if (res.data.status === 0) {
          getPictureStreamPath({pictureId: res.data.data.ID }).then(res1 => {
            let url = res1.data.data.url 
            fn && fn(url) 
          })
        } else {
          this.$message.error('图片上传失败')
          fn && fn('')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.citationPushConfig {
  .center_2 {
    text-align: left;
    min-height: 400px;
  }
}
</style>
